<template>
  <div class="search-mian">
    <!-- 导航栏 -->
    <public-header
      :searchValue="searchValue"
      @resetForm="resetForm"
      @clickSearch="clickSearch"
      @goSearchLabel="goSearchLabel"
    ></public-header>
    <!-- 检索主体 -->
    <div class="page_bd">
      <!-- 筛选页面 -->
      <SearchHead
        ref="headObj"
        :cate="cate"
        :searchValue="search.name||search.uniform_numbers"
        @searchDataCk="searchDataCk"
        @cancelSearchValue="cancelSearchValue"
      ></SearchHead>
      <!-- 主体商品展示页面 -->
      <div ref="main" class="shop-cen" v-loading="loading">
        <!-- 头部搜索商品数量 -->
        <div class="shop-cen-head">
          <div>
            <span>共找到</span>
            <span class="total-number">{{total|formatNumber}}</span>
            <span>{{$t('家企业')}}</span>
            <span v-if="total>5000">，{{$t('为您优先匹配前5000家企业')}}。</span>
          </div>
          <el-dropdown split-button @command="dropdownCk" size="mini">
            {{orderText}}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">{{$t('资本额降序')}}</el-dropdown-item>
              <el-dropdown-item command="2">{{$t('资本额升序')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 内容展示 -->
        <Content
          :contentData="contentData"
          :companies_id="companies_id"
          :loading="loading"
          @fatherCk="fatherCk"
        ></Content>
        <!-- 分页 -->
        <el-pagination
          background
          layout="total,prev, pager, next"
          :total="total>5000 ? 5000 : total"
          :current-page.sync="search.page"
          @current-change="pageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 回到顶部 -->
    <back-top></back-top>
    <public-footer></public-footer>
  </div>
</template>

<script>
//组件引入
import Content from '../../components/body/content.vue'
import SearchHead from '../../components/search/search_head.vue'
export default {
  data() {
    return {
      checkList: [],
      total: 0,
      page_size: 10,
      // 内容模块
      contentData: [],
      companies_id: [], //批量生成公司id
      // 搜索条件
      searchValue: '',
      search: {
        bonus: [],
        labels: [],
        name: '',
        order: 'desc',
        page: 1,
        page_size: 10,
        type: [],
        parent_path: '',
        uniform_numbers: '',
      },
      cate: '', //默认路径
      loading: false,
    }
  },
  computed: {
    orderText() {
      if (this.search.order == 'desc') {
        return this.$t('资本额降序')
      } else if (this.search.order == 'asc') {
        return this.$t('资本额升序')
      } else {
        return this.$t('默认排序')
      }
    },
  },
  methods: {
    init() {
      this.getCompanyList()
    },
    // 重置form
    resetForm(val) {
      this.$refs.headObj.resetForm(val)
    },
    fatherCk(val) {
      this.contentData.find(
        (item) => item.company.ID === val.company_id
      ).collection_group_id = val.let
      this.$forceUpdate()
    },
    // 点击搜索栏
    clickSearch(val) {
      this.searchValue = val.trim()
      let exp = /\d{7,8}/
      if (exp.test(this.searchValue)) {
        this.search.name = ''
        this.search.uniform_numbers = this.searchValue
      } else {
        this.search.name = this.searchValue
        this.search.uniform_numbers = ''
      }
    },
    // 分页 触发子组件搜索方法
    pageChange(e) {
      this.getCompanyList()
      this.$refs['main'].scrollIntoView()
    },
    //我的收藏
    faClick() {
      this.$router.push({
        path: '/favorites',
      })
    },
    //浏览记录
    ecClick() {
      this.$router.push({
        path: '/ecords',
      })
    },
    //首页
    homeCk() {
      this.$router.push({
        path: '/index',
      })
    },
    //当前页面进入
    goSearchLabel() {
      this.$refs.headObj.labelLoad(1089)
    },
    // 检索方法子组件
    getCompanyList() {
      let params = this.search
      this.contentData = []
      this.loading = true
      this.$service
        .getCompanyList(params)
        .then((res) => {
          this.contentData = res.rows
          this.total = res.total
          this.loading = false
        })
        .catch(() => {
          this.contentData = []
          this.total = 0
          this.loading = false
        })
    },
    // 检索内容
    searchDataCk(val) {
      this.search = { ...this.search, ...val }
      this.getCompanyList()
    },
    dropdownCk(e) {
      if (e == 1) {
        this.search.order = 'desc'
      } else {
        this.search.order = 'asc'
      }
      this.getCompanyList()
      return
    },
    cancelSearchValue() {
      this.searchValue = ''
    },
  },
  components: {
    Content,
    SearchHead,
  },
  mounted() {
    const { cate } = this.$route.params
    const { name, labelId } = this.$route.query
    if (cate !== undefined) {
      this.cate = cate
    } else if (labelId !== undefined) {
      this.search.bonus.push(Number(labelId))
      this.$refs.headObj.labelLoad(Number(labelId))
    } else if (name) {
      this.searchValue = name
      this.clickSearch(name)
    } else {
      this.getCompanyList()
    }
  },
}
</script>

<style lang="scss" scoped>
.search-mian {
  height: 100vh;

  .shop-cen {
    display: flex;
    flex-flow: column;
    font-weight: 400;
    margin: 20px 0;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    background: #fff;
  }

  .shop-cen-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 10px;
    margin: 0rem 1rem;
    border-bottom: 1px solid #f2f2f2;
    color: #333;
    font-size: 14px;
    .total-number {
      color: #007aff;
      font-weight: 800;
      padding: 0 4px;
    }
  }
  .el-pagination {
    margin: 50px 0 40px 0;
    text-align: center;
  }
}
</style>
